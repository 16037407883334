<template>
  <div class="floor-course">
    <div
      style="
        overflow: hidden;
        height: 40px;
        display: flex;
        justify-content: space-between;
      "
    >
      <el-input
        placeholder="课程名称"
        v-model="input3"
        class="input-with-select"
        style="width: 30%;"

      >
        <el-button
          slot="append"
          icon="el-icon-search"
          size="mini"
          @click="searchFun"
          style=""
        ></el-button>
     
      </el-input>
      <el-pagination
      hide-on-single-page
      v-if="newsBookAryCur.length!=0"
        small
  
        :total="totalCur"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="8"
        style="text-align: right;    margin-top: 9px;"
      >
      </el-pagination>
    </div>
    <div class="bd floor-side-body" v-if="newsBookAryCur.length>0">
      <ul style="margin-left: -12px;">
        <li v-for="(item,index) in newsBookAryCur" :key="index" @click="goto(item)">
          <a href="javascript:;">
            <div class="image-ti">
              <p>
                <el-tag type="warning"   effect="dark" v-for="(t,i) in item.tags"  :key="i" size="mini">{{ t }}</el-tag>
              </p>
             
              <img :src="item.imgpath?item.imgpath:require('../images/lo001.jpg')" alt="" />
              
            </div>
            <p class="slide-title">{{item.title}}</p>
            <p class="slide-body">
              <span class="slide-body-nub">
                <i class="slide-body-nub-icon"></i>
                {{ item.joinnumber }}
              </span>
              <!-- <span class="slide-body-nup">
                <i class="slide-body-nup-icon"></i>
                230
              </span> -->
              <span class="slide-body-price">免费</span>
            </p>
          </a>
        </li>
      </ul>
    </div>
    <el-empty description="暂无数据" v-else></el-empty>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      currentPage3: 1,
      newsBookAryCur:[],
      totalCur:'',
      input3:''
    };
  },
  props: {
    total: Number,
    newsBookAry: Array,
  },
  watch: {
    newsBookAry(val) {
      console.log(val);
      this.newsBookAryCur = val;
      console.log(this.newsBookAryCur);
    },
    total(val) {
      console.log(val);
      this.totalCur = val;
      console.log(this.totalCur);
    },
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.emit('changeSize',val)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      //   this.page = val;
      //   //   this.initColumn();
        this.$emit("changePage", val);
    },
    searchFun() {
  
  window.localStorage.setItem("anjing_input3_name", this.input3);
  // if (window.location.href.indexOf("search_list") != -1) {
  //   this.$emit("searchFun", "0");
  // } else {
    this.$router.push({ path: "./kc" });
  // }
},
goto(obj){
  window.location.href=`/#/more?seqid=${obj.seqid}`
  // this.$router.push({ path: "/#/more",query:{seqid:obj.seqid} });
  window.localStorage.setItem('morePath_anjing','首页')
}
  },
};
</script>
<style  scoped>
@import url(../assets/style.css);
.image-ti{
  position: relative
}
.image-ti p{
  position: absolute;
  top: -4px;
  left:0;
  width:100%;
}
.image-ti span{
  margin-right:5px !important;
  font-weight: bold;
  /* color:#fff;
  back
  float:left;
  margin-right:10px !important;
  width:auto;
  padding:2px 10px;
  height:30px;
  margin:0;
  padding:0; */
  
}
</style></style>