<template>
     <div class="case-container">
        <div
      style="
        overflow: hidden;
        height: 40px;
        display: flex;
        justify-content: space-between;
      "
    >
      <el-input
        placeholder="书名、作者"
        v-model="input3"
        class="input-with-select"
        style="width: 30%;margin-bottom:1% ;"

      >
        <el-button
          slot="append"
          icon="el-icon-search"
          size="mini"
          @click="searchFun"
          style=""
        ></el-button>
     
      </el-input>
      <el-pagination
      hide-on-single-page
      v-if="newsBookAryCur.length!=0"
        small
        layout="prev, pager, next"
        :total="totalCur"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="10"
        style="text-align: right;margin-top: 8px;"
      >
      </el-pagination>
    </div>
            <div class="case-index-content" v-if="newsBookAryCur.length>0">
                <ul class="clearfix">
                    <li class="case-example-item" v-for="(item,index) in newsBookAryCur" :key="index" @click="goTo(item)">
                        <a href="javascript:;">
                            <!-- <img src="https://ebook.t.educg.com/fileDownload.out?fid=649a33dcada77f77c26ef73d" alt="" class="case-pad"> -->
                            <img class="case-pad" :src="item.imgpath?item.imgpath:require('../images/lo002.jpg')" alt="图片加载异常"/>
                            <div class="case-item-info">
                           
                                <div class="case-info-title" >{{ item.title }}</div>
                                <div >
		                    	<span style="font-size: 12px;color:rgb(147 145 145);">   <i class="slide-body-nub-icon"></i>{{ item.joinnumber }}</span>
		                    </div>
                            <p style="color:rgb(126 124 124);font-weight: bold;margin-bottom:14px;">作者：{{ item.author?item.author:'暂无' }}</p>
                                <div class="case-info-text">{{ item.intro }}</div>
                            </div>

                        </a>
                    </li>
                  
                </ul>
            </div>
            <el-empty description="暂无数据" v-else></el-empty>
        </div>
</template>
<script>
export default {
    name: "",
    data() {
      return {
        currentPage3: 1,
        newsBookAryCur:[],
        totalCur:'',
        input3:''
      };
    },
    props: {
      total: Number,
      newsBookAry: Array,
    },
    watch: {
      newsBookAry(val) {
        console.log(val);
        this.newsBookAryCur = val;
        console.log(this.newsBookAryCur);
      },
      total(val) {
        console.log(val);
        this.totalCur = val;
        console.log(this.totalCur);
      },
    },
    methods: {
 
      goTo(obj) {
        console.log(0);
        window.localStorage.setItem("anjing_detail_new", JSON.stringify(obj));
        window.location.href=`/#/course?id=${obj.seqid}`
    
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        // this.emit('changeSize',val)
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.page = val;
        //   this.initColumn();
        this.$emit("changePage", val);
      },
      goTo(obj) {
      console.log(0);
      window.localStorage.setItem("anjing_detail_new", JSON.stringify(obj));
      window.location.href=`/#/course?id=${obj.seqid}`
  
    },
    searchFun() {
      // let data = {
      //   op: "search",
      //   page:1,
      //   limit:10,
      //   name:this.input3

      // };
      // let _this = this;
      // this.$ajax
      //   .post(this.url + "/index.do", _this.Qs.stringify(data))
      //   .then(function (response) {
      //     console.log(response);
      //     if (response.data.value == "ok") {

      //     }
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
      window.localStorage.setItem("anjing_input3_name", this.input3);
      if (window.location.href.indexOf("search_list") != -1) {
        this.$emit("searchFun", "0");
      } else {
        this.$router.push({ path: "./search_list" });
      }
    },
    },
  };
</script>
<style>/* 公共样式表css */
html, body {
	color: #333;
	margin: 0;
	height: 100%;
}
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
a {
	text-decoration: none;
	color: #000;
}
img {
	border: 0;
}
body {
	background: #F4F5F9;
	color: #666;
}

a {
	text-decoration: none;
	color: #08acee;
}
button {
	outline: 0;
}
img {
	border: 0;
}
button, input, optgroup, select, textarea {
	margin: 0;
	font: inherit;
	color: inherit;
	outline: none;
}
li {
	list-style: none;
}
a {
	color: #666;
}
a:hover {
	color: #eee;
}
.clearfix::after {
	clear: both;
	content: ".";
	display: block;
	height: 0;
	visibility: hidden;
}
.clearfix {
}
/* 必要布局样式css */
.case-container {
	width: 1200px;
	margin: 0 auto;
	padding: 0;
}
.case-index-title {
	font-size: 16px;
	text-align: center;
	padding: 50px 0;
}
.case-index-title h2 {
	font-size: 36px;
	font-family: pingfang, "Microsoft Yahei", "HanHei SC", PingHei, "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
	font-weight: normal;
}
.case-index-title a {
	width: 100px;
	height: 36px;
	line-height: 36px;
	border: 1px solid #DBDCE0;
	text-align: center;
	font-size: 14px;
	display: block;
	margin: 25px auto 0;
	transition: all .2s;
}
.case-index-title a:hover {
	background: #F96A69;
	border-color: #F96A69;
	color: #fff;
}
.case-index-content {
	position: relative;
    margin-top:13px;
}
.case-index-content ul {
	margin-right: -18px;
}
.case-example-item {
	float: left;
	width: 18.5%;
	height: 315px;
	overflow: hidden;
	margin: 0 18px 18px 0;
	text-align: center;
	background: #fff;
	position: relative;
	transition: all .2s;
}
.case-example-item a .case-pad {
	/* width: 286px; */
	height: 180px;
    margin-top:10px;
}
.case-item-info {
    text-align: center;
	height: 128px;
	overflow: hidden;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
    padding:5px;
	transition: all .2s;
}
.case-info-logo {
	margin-top: 0;
	margin-bottom: 8px;
	height: 16px;
	transition: all .2s;
}
.case-info-title {
    margin-top:12px;
    font-size: 16px;
    color: #3d3c3c;
	margin-bottom: 5px;
    height:42px;
    font-weight: bold;
    display: -webkit-box;
    /* text-align: left; */
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.case-info-text {
	font-size: 14px;
	color: #6b6b6b;
	/* padding: 0 5px; */
    overflow: hidden;
	text-align: left;
	line-height: 1.8;
    text-overflow: ellipsis;
  display: -webkit-box;

  width:95%;

  -webkit-line-clamp: 5; /* 设置行数 */
  -webkit-box-orient: vertical;
}
.case-index-content .case-example-item:hover .case-item-info {
	height: 315px;
}
</style>