<template>
  <div class="footer" style="width: 100%">
    <div
    class="box-box"
      :style="{
        width: addWindh,
        margin: '0px auto',
        display: 'flex',
        flexWrap:'wrap',
        color: '#fff',
    
      }"
    >
      <!-- <div>
        <h2>专业建设方案</h2>
        <ul>
          <li>人工智能</li>
           <li>大数据</li>
          <li>计算机/系统能力培养</li>
          <li>信息安全</li>
          <li>区块链</li>
          <li>集成电路</li>
           <li>智能医学</li>

        </ul>
      </div> -->
      <div>
        <h2>教育数字基建</h2>
        <ul>
      <li><a href="https://www.educg.net/research.html" target="_blank">智能算力服务平台</a></li>
          <li><a href="https://www.educg.net/OBE.html" target="_blank">工程教育认证</a></li>
          <li><a href="https://www.educg.net/graduation.html" target="_blank">毕业设计</a></li>
          <li><a href="https://www.educg.net/scangrade.html" target="_blank">纸质试卷在线评阅</a></li>
          <li><a href="https://www.educg.net/exam.html" target="_blank">在线考试</a></li>
          <li><a href="https://www.educg.net/mooc.html" target="_blank">慕课</a></li>
          <li><a href="https://plag.educg.net/" target="_blank">图文与代码查重</a></li>
          <li><a href="https://eduxiji.com/" target="_blank">理工类专业智慧教学平台</a></li>
          <li><a href="https://bd.educg.net/" target="_blank">教育大数据平台</a></li>
          <!-- <li><a href="xx" target="_blank">新形态数字教材平台</a></li> -->
        </ul>
      </div>
      <div>
        <h2>教学实验平台建设方案</h2>
        <ul>
       <li><a href="https://www.educg.net/robot.html" target="_blank">云端机器人实验平台</a></li>
          <li><a href="https://www.educg.net/cloudDesk.html" target="_blank">新一代云桌面实验平台</a></li>
          <li><a href="https://www.educg.net/fpga-cloud.html" target="_blank">FPGA实验云平台</a></li>
          <li><a href="https://3desk.educg.com/" target="_blank">3D云桌面</a></li>
          <li><a href="https://www.educg.net/contest.html" target="_blank">比赛</a></li>
          <li><a href="https://www.educg.net/oj.html" target="_blank">OnlineJudge</a></li>
          <li><a href="https://www.educg.net/exp.html" target="_blank">在线虚拟实验开发环境</a></li>
        </ul>
      </div>
      <div>
        <h2>课程建设方案</h2>
        <ul>
         <li><a href="https://www.educg.net/programming.html" target="_blank">程序设计</a></li>
          <li><a href="https://www.educg.net/ds.html" target="_blank">算法与数据结构</a></li>
          <li><a href="https://www.educg.net/os.html" target="_blank">操作系统</a></li>
          <li><a href="https://www.educg.net/computer.html" target="_blank">计算机组成原理</a></li>
          <li><a href="https://www.educg.net/compiler.html" target="_blank">编译技术实验</a></li>
          <li><a href="https://www.educg.net/database.html" target="_blank">数据库实验</a></li>
          <li><a href="https://www.educg.net/se.html" target="_blank">软件工程</a></li>
          <li><a href="https://www.educg.net/parallel.html" target="_blank">并行计算</a></li> 
          <li><a href="https://www.educg.net/network.html" target="_blank">计算机网络</a></li>
          <li><a href="https://www.educg.net/ai.html" target="_blank">人工智能类课程</a></li>
          <li><a href="https://www.educg.net/bigdata.html" target="_blank">大数据类课程</a></li>
          <li><a href="https://www.educg.net/blockchain.html" target="_blank">区块链类课程</a></li>
          <li><a href="https://www.educg.net/applied_courses.html" target="_blank">应用开发类课程</a></li>

        </ul>
      </div>
      <div>
        <h2>联系我们</h2>
        <ul>
          <li><p>产品合作</p> <p style="font-size:18px;color:rgb(129, 245, 129);font-weight: bold;"><i class="el-icon-phone-outline"></i>19910509699</p></li>
          <li><p>服务热线</p> <p style="font-size:18px;color:rgb(129, 245, 129);font-weight: bold;"><i class="el-icon-phone-outline"></i>4000697117</p></li>
          <li> <p>在线咨询</p>
          <p>
            <img
        style="width: 80px; height: 80px"
        src="../images/WechatIMG3783.png"
      />
          </p></li>
          
   
          
        </ul>
      </div>
   
    </div>
    <div
      class="line"
      :style="{
        width: addWindh,
        margin: ' 0 auto',
      }"
    >
      <!-- <el-divider></el-divider> -->
    </div>
    <div
      :style="{
        width: addWindh,
        margin: '0px auto',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#fff',
      }"
    >
  
 
      
  
    </div>
   
     <div style="margin-right:40px;"  class="line"  :style="{
        width: addWindh,
        margin: '0px auto'}">
  <el-divider ></el-divider>
    
      <!-- <h2 style="color:#fff">
      服务与支持
    </h2> -->
    
      <a href="https://course.educg.net" target="_blank"
        >希冀在线实践教学平台</a
      >
      <el-divider direction="vertical"></el-divider>

      <a href="https://www.educg.net/" target="_blank"
        >希冀平台官网
      </a>
      <el-divider direction="vertical"></el-divider>

      <a href="https://os.educg.net/" target="_blank"
        >全国计算机系统能力大赛
      </a>
      <el-divider direction="vertical"></el-divider>

      <a href="https://test.csia.org.cn/" target="_blank"
        >全国青少年信息技术与计算思维能力评测（TCTY）
      </a>
      <el-divider direction="vertical"></el-divider>

      <a href="https://test.csia.org.cn/" target="_blank"
        >全国青少年人工智能应用与创新能力评测（AITY）
      </a>
      <el-divider direction="vertical"></el-divider>

<a href="https://sat.csia.org.cn/" target="_blank"
  >信息技术人才-软件能力评测
</a>
      
      

  
      <p style="margin-top: 10px;">
        <span  style="    color: #ffbf64;"
          >©北京航空航天大学 计算机学院 教学成果转化平台</span
        >
        <el-divider direction="vertical"></el-divider>
        <span  style="    color: #ffbf64;"
          >阔思格睿网络科技（北京）有限责任公司</span
        >
        <el-divider direction="vertical"></el-divider>
        <span  style="    color: #ffbf64;"
          >郑州云海科技有限公司</span
        >

       
      </p>
      <p>  
        
        <a href="https://beian.miit.gov.cn/" target="_blank" style="    color: #fff;" >豫ICP备18015569号-15</a> <el-divider direction="vertical"  v-if="temp"></el-divider> 
        <span  style="    color: #fff;" v-if="temp">注：以上课程的所有权归{{qy}}所有</span></p>
        <p style="color: #fff;">办公地址：北京市知春路锦秋国际大厦1404室</p>
       
    

    </div>
  
    <!-- <div>
      <p
        style="
          margin-bottom: 10px;
          font-weight: bold;
          color: #fff;
          font-size: 14px;
        "
      >
        联系我们
      </p>
      <img
        style="width: 80px; height: 80px"
        src="../images/WechatIMG3783.png"
      />
    
    </div>  -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      temp: false,
      addWindh: "1340px",
    };
  },
  props: {
    qy: String,
    // addWindh:String
  },
  mounted() {
    let windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (window.location.href.indexOf("temp") != -1 || windowWidth < 880) {
      this.temp = true;
      this.addWindh = "100%";
    } else {
      this.temp = false;
      this.addWindh = "1340px";
    }
  },
};
</script>
  <style scoped>
  ul,li{
    padding:0 ;
    margin:0;
    list-style: none;
  }
  ul li{
    padding:5px 0;
    color:#ccc;
    cursor: pointer;

    margin:0;

  

  }
.footer {
  background: #4e4e4e;
  padding: 15px 5%;
  /* height:70px; */
  box-sizing: border-box;
  /* display: flex;
  justify-content: space-around; */
  text-align: left;
  font-size: 14px;
  overflow: hidden;
}
a {
  color: #d4d4d4;
  text-decoration: none;
}
.ant-divider {
  background: #0fb4f3;
}
.footer > div {
  /* float: left; */
}
.line .el-divider--horizontal {
  margin: 24px 0 !important;
}
.box-box>div{
  margin-right:13%;
}
</style>