<template>
  <div class="home el-scrollbar">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <heade msg="Welcome to Your Vue.js App"></heade>
    <!-- <div style="position: fixed;right:5px;top:100px;">
      <ul class="ui-add">
        <li style="color:#ff6100"><i class="el-icon-position" style="color: #ff6100"> </i>关于我们</li>
        <li><i class="el-icon-thumb"></i>在线开课</li>
        <li style="color: rgb(19 182 5)"><i class="el-icon-chat-dot-square" style="color: rgb(19 182 5)"> </i>在线咨询</li>
      </ul>
    </div> -->
    <div class="page-component__scroll">
    <!-- <div class="floor-course" id="f">
      <div class="floor-content">
        <div>
          <el-tabs v-model="activeNameNewKc" @tab-click="clickKcFun">
            <el-tab-pane
              label="在线课程"
              name="0"
              disabled
              style="font-weight: bold"
            ></el-tab-pane>
            <el-tab-pane label="全部" name="全部">
          
                <tabComm
                :newsBookAry="courseBook"
                :total="courseBookCount"
                @changePage="changePagecourseBook"
              ></tabComm> 
            </el-tab-pane>
            <el-tab-pane
              :label="item.label"
              :name="item.value"
              v-for="(item, index) in typeListKc"
              :key="index"
            >
            <tabComm
                :newsBookAry="courseBook"
                :total="courseBookCount"
                @changePage="changePage"
              ></tabComm> 
          </el-tab-pane>

            <el-tab-pane label="【 更多 】" name="more" class="more"
              ></el-tab-pane
            >
          </el-tabs>
        </div>
      </div>
    </div> -->
    <div class="floor-course"  id="s" style="background-color: #f5f8fa;    padding: 20px 0;">
      <div class="floor-content">
        <div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              label="课程速递"
              name="0"
              disabled
              style="font-weight: bold"
            ></el-tab-pane>
            <el-tab-pane label="全部" name="全部">
              <!-- <card
                :newsBookAry="newsBookAry"
                :total="newsBookCount"
                @changePage="changePage"
              ></card> -->
            
              <!-- <newComCard
                :newsBookAry="newsBookAry"
                :total="newsBookCount"
                @changePage="changePage"
              ></newComCard> -->
              <cardKc   :newsBookAry="newsBookAry"
                :total="newsBookCount"
                @changePage="changePage"></cardKc>
            </el-tab-pane>
            <el-tab-pane
              :label="item.title"
              :name="item.title"
              v-for="(item, index) in typeList"
              :key="index"
            >
              <!-- <card
                :newsBookAry="newsBookAry"
                :total="newsBookCount"
                @changePage="changePage"
              ></card
            > -->
            <cardKc   :newsBookAry="newsBookAry"
                :total="newsBookCount"
                @changePage="changePage"></cardKc>
          
          </el-tab-pane>

            <el-tab-pane label="【 更多 】" name="more" class="more"
              >跳转到更多展示</el-tab-pane
            >
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="floor-content"  id="t"
        style="
       
          padding: 20px 5px 55px;
          margin-top: 20px;
          border-radius: 5px;
        "
      >
        <h3>
          课程专栏
          <i
            class="el-icon-s-promotion"
            style="color: #ff3c00; margin-bottom: 15px"
          >
          </i>
        </h3>
        <div
          class="gg"
          style="display: grid; grid-template-columns: 34% 33% 34%"
        >
          <dl
            @click="linshi(item._id)"
            v-for="(item, index) in ztAry"
            :key="index"
          >
            <dt>
              <img :src="item.iconpath" width="60px" />
            </dt>
            <dd>
              <p>{{ item.title }}</p>
              <span class="co"> {{ item.comment }}</span>
            </dd>
          </dl>
          
        </div>
      </div>
    <div class="floor-course" style="background-color: #f5f8fa;    padding: 20px 0;" v-if="moreAry.length==0&& activeList!='全部'">
      <div class="floor-content">
        <div>
    <el-tabs v-model="activeList" @tab-click="handleClickMore">
            <el-tab-pane
              label="最受关注图书榜"
              name="0"
              disabled
              style="font-weight: bold"
              >最受关注图书榜</el-tab-pane
            >
            <el-tab-pane label="全部" name="全部">
              <!-- <hxCard :moreAry="moreAry" :moreCount="moreCount"></hxCard> -->
              <cardKcT  :newsBookAry="moreAry" :total="moreCount"                 @changePage="changePaget"
              ></cardKcT>
            </el-tab-pane>
            <el-tab-pane
              :label="item.title"
              :name="item.title"
              v-for="(item, index) in typeList"
              :key="index"
            >
              <!-- <cardList :moreAry="moreAry" :moreCount="moreCount"></cardList
            > -->
            <cardKcT  :newsBookAry="moreAry" :total="moreCount"                 @changePage="changePaget"
              ></cardKcT>
          </el-tab-pane>

            <el-tab-pane label="【 更多 】" name="more" class="more"
              >跳转到更多展示</el-tab-pane
            >
          </el-tabs>
          </div>
          </div>
          </div>

    <div>
      
     
  
     
    </div>
  
  

    <footerC :chnageWidth="chnageWidth"></footerC>
    <div class="leftNav-item">
		<ul>
      <el-tooltip class="item" effect="dark" width="200px"  placement="left">
			  <div slot="content">
          <p style="font-size:16px;font-weight:bold">产品合作：19910509699</p>
          <br/>
          <p style="font-size:16px;font-weight:bold">服务热线：4000697117</p>
          <br/>
          <p style="font-size:16px;font-weight:bold">( 在线咨询扫一扫 )</p>
          <br/>
          <p>
            <img
        style="width: 80px; height: 80px"
        src="../images/WechatIMG3783.png"
      />
          </p>
        </div>
        <li>
       
				<i class="el-icon-s-home"></i>
				<a href="javascript:void(0)" class="rota">联系我们</a>
      
			</li>
    </el-tooltip>
			<li title="点击前往">
				<i class="el-icon-chat-dot-square"></i>
				<a href="https://devms.t.educg.com/#/zl"   target="_blank" class="rota">在线咨询</a>
			</li> 
			<!-- <li lay-data="img" data-fooc="images/zizhu.png">
				<i class="fa fa-money"></i><a href="javascript:;" class="rota">小额打赏</a>
			</li>  -->
			<li title="返回顶部" class="for-top" onclick="topFun()">
				<i class="el-icon-upload2"></i>
				<a href="javascript:;" class="rota">去顶部</a>
			</li>
		</ul>
	</div>
  </div>
</div>
</template>
<!--  -->
<script>
// @ is an alias to /src
import heade from "@/components/heade.vue";
import card from "@/components/card.vue";
import cardPhone from "@/components/cardPhone.vue";
import cardList from "@/components/cardList.vue";
import cardListPhone from "@/components/cardListPhone.vue";

import hxCard from "@/components/hxCard.vue";
import newComCard from "@/components/newComCard.vue";
import cardKc from "@/components/cardKc.vue";
import cardKcT from "@/components/cardKcT.vue";
import tabComm from "@/components/tabComm.vue";
import footerC from "@/components/footerCommon.vue";
import HxCard from "@/components/hxCard.vue";

export default {
  name: "Home",
  components: {
    heade,
    card,
    cardKc,
    cardKcT,
    newComCard,
    hxCard,
    cardPhone,
    cardList,
    cardListPhone,
    footerC,
    tabComm,
    HxCard
},
  data() {
    return {
      dialogVisible: false,
      chnageWidth: "1340px",
      typeUrl: "pc",
      activeName: "全部",
      activeList: "全部",
      activeNameNew:'全部',
      activeNameNewKc:'全部',
      page: 1,
      pageKc:1,
      limit: 10,
      limitK:8,
      column: "课程速递",
      type: "全部",
      typeList: [],
      typeListKc:[],
      newsBookAry: [],
      courseBook:[],
      courseBookCount:0,
      moreAry: [],
      moreCount: 0,
      newsBookCount: 0,
      ztAry: [],
      typeKc:'全部'
    };
  },
  mounted() {
    this.init();
    this.ztFun();
    this.initKc();
  

    let windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    console.log(windowWidth);
    if (windowWidth < 880) {
      //移动端
      this.typeUrl = "phone";
      this.chnageWidth = "100%";
    } else {
      this.typeUrl = "pc";
      this.chnageWidth = "1340px";
    }
  },
  methods: {
    init() {
      let data = {
        op: "typelist",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.typeList = response.data.data;
              _this.initColumn("课程速递", 1);
            _this.initColumn("最受关注图书榜", 1);
          
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    initKc(){
      //typeListKc
      let data = {
        op: "typelist",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/course.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.typeListKc = response.data.data;
            _this.initcourseBook()
            // _this.initColumn("课程速递", 1);
            // _this.initColumn("最受关注图书榜", 1);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    ztFun() {
      let data = {
        op: "indexlist",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/company.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.ztAry = response.data.data;
            // _this.initColumn("新书速递", 1);
            // _this.initColumn("最受关注图书榜", 1);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    changePage(page) {
      this.page = page;
      this.initColumn("课程速递", 1);
   
    },
    changePagecourseBook(page){
      this.pageKc = page;
      this.initcourseBook();
    },
    changePaget(page) {
      this.page = page;
      this.initColumn("最受关注图书榜", 1);
 
    },
    linshi(id) {
      // const { href } = this.$router.resolve({
      //   path: "/temp",
      //   query:{id:id}
      // });
      // window.open(href, "_blank");
      this.$router.push({ path: "/temp", query: { id: id } });
    },
    searchFun() {
  
      window.localStorage.setItem("anjing_input3_name", this.input3);
      if (window.location.href.indexOf("search_list") != -1) {
        this.$emit("searchFun", "0");
      } else {
        this.$router.push({ path: "./search_list" });
      }
    },
    initColumn(column, istop) {
      debugger;
    if(column =='课程速递'){
      if (this.activeName == "全部") {
        this.type = "";
      }
      else{
        this.type = this.activeName;
      }
      }
      else if(column =='最受关注图书榜'){
        if (this.activeList == "全部") {
        this.type = "";
      }
      else{
        this.type = this.activeList
      }
       
      }
      let data = {
        op: "list",
        page: this.page,
        column: column,
        type: this.type,
        limit: this.limit,
        istop: istop,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            if (column == "课程速递") {
              _this.newsBookAry = response.data.data;
              _this.newsBookCount = response.data.recordcount;
            } else {
              _this.moreAry = response.data.data;
              _this.moreCount = response.data.recordcount;
              console.log(_this.moreCount )
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 课程数据
    initcourseBook(){
      debugger;
      if (this.activeNameNewKc == "全部") {
        this.typeKc = "";
      }
      else{
        this.typeKc = this.activeNameNewKc;
      }
      let data = {
        op: "search",
        page: this.pageKc,
        limit: this.limitK,
        name:'',
        type: this.typeKc,
        stype:'',
        column:'',
        tag:''

        
        
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/course.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.courseBook = response.data.data;
              console.log('_this.courseBook',_this.courseBook)
              _this.courseBookCount = response.data.recordcount;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleClickMore(val) {
      console.log(val.label);
      if (val.label == "【 更多 】") {
        this.$router.push({ path: "./more_list" });
        window.localStorage.setItem("anjing_more_type", "最受关注图书榜");
      } else {
        this.type = val.label;
        this.activeList = val.label;
        this.page=1
        this.initColumn("最受关注图书榜", 1);

      }
    },
    ClickMore(val) {
      debugger;
      console.log(9);
      this.$router.push({ path: "./more_list" });
      window.localStorage.setItem("anjing_more_type", val);
    },
    handleClick(val) {
      debugger;
      console.log(val.label);
      if (val.label == "【 更多 】") {
        this.$router.push({ path: "./more_list" });
        window.localStorage.setItem("anjing_more_type", "课程速递");
      } else {
        this.type = val.label;
        this.activeName=  val.label;
        this.page=1;
        this.initColumn("课程速递", 1);
    
        console.log( this.page)
      }
    },
    clickKcFun(val){
      debugger;
      console.log(val)
  
      if (val.label == "【 更多 】") {
        this.$router.push({ path: "./kc" });
        window.localStorage.setItem("anjing_more_type", "");
      } else {
        this.typeKc = val.label;
        this.activeNameNewKc=  val.label;
        this.pageKc=1;
        // 还没接口

        this.initcourseBook()
    
        console.log( this.page)
      }
    }
   
  },
};
</script>
<style scoped>
h3 {
  padding: 0;
  margin: 0;
  text-align: left;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.gg dl {
  overflow: hidden;
  /* width: 30%; */
  margin-right: 10px;
  display: flex;
  padding: 15px 10px;
  text-align: left;
  background: #ffffff;
  margin-top: 10px;
  cursor: pointer;
  max-height: 87px;
  box-shadow: 0px 0px 5px 0px #dbdbdb;
}
.gg dl dt {
  margin-right: 15px;
}
.gg dl dd p {
  font-weight: bold;
}

.tab {
  max-width: 1000px;
  /* margin: 0 auto; */
  min-width: 740px;
}
.co {
  color: #989494;
}
.left-title {
  padding-right: 50px;
  line-height: 40px;
  font-weight: bold;
  height: 38px;
}
.left-title-b {
  font-weight: bold;
  width: 270px;
}
.right-more {
  padding-left: 50px;
  line-height: 40px;
  height: 38px;
  font-size: 14px;
}
.phone-more {
  color: rgb(1, 93, 221);
  cursor: pointer;
  font-size: 14px;
}
.pcOrphone {
  width: 1340px;
  margin: 0 auto;
  display: flex;
}
.phone {
  display: none;
}
.ui-add{
  overflow: auto;;
}
.ui-add li{
  height:30px;
  background:pink;
  margin-bottom:10px;
  line-height:30px;
  padding:0 10px;
}
.leftNav-item {
    position: fixed;
    right: 5px;
    bottom: 20%;
    z-index: 999999;
}
.leftNav-item ul{
	margin: 0;
	padding: 0;
}
.leftNav-item ul li {
	width: 60px;
	height: 60px;
	font-size: 20px;
	border-radius: 5px;
	background: #fff;
	color: #2d2d2d;
	text-align: center;
	margin: 5px 0;
	cursor: pointer;
	position: relative;
	transition: .5s;
	box-shadow: 0 2px 5px rgba(0,0,0,0.8);
}
.leftNav-item ul li i {
	color: #666;
	line-height: 60px;
	font-size:20px;
}

.leftNav-item ul li .rota {
	display: block;
	position: absolute;
	border-radius: 5px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	margin: auto;
	-webkit-transform-origin: -50% 50%;
	transform-origin: -50% 50%;
	-webkit-transform: rotate3d(0,0,1,90deg);
	transform: rotate3d(0,0,1,90deg);
	-webkit-transition: -webkit-transform .3s ease-in;
	transition: transform .3s ease-in;
	opacity: 0;
	filter: alpha(opacity=0);
	background: #1B8BF5;
	color: #fff;
	text-align: center;
	line-height: 60px;
	font-size: 12px;
	text-decoration: none;
	padding: 2px 3px;
	box-sizing: border-box;
}

.leftNav-item ul li:hover .rota {
	-webkit-transform: rotate3d(0,0,1,0deg);
	transform: rotate3d(0,0,1,0deg);
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	opacity: .9;
	filter: alpha(opacity=90);
}

.leftNav-item ul li .title {
	position: absolute;
	right: -72px;
	top: 16px;
	height: 18px;
	line-height: 18px;
	font-size: 14px;
	text-align: left;
	padding: 3px 10px;
	background: rgba(0,0,0,0.5);
	color: #fff;
	border-radius: 8px;
}

.leftNav-item ul li .img-item {
	position: absolute;
	left: 65px;
	top: -30px;
	border: 1px solid #ccc;
	border-radius: 3px;
}

.leftNav-item ul li div img {
	max-width: 120px;
	display: block;
}

.leftNav-item ul li .text-item {
	position: absolute;
	left: 65px;
	top: 18px;
	border: 1px solid #ccc;
	border-radius: 3px;
	background: #fff;
	text-decoration: none;
	padding: 3px 8px;
	color: #444;
	font-size: 14px;
}

.leftNav-item ul li .text-item span {
	position: relative;
	padding-left: 15px;
	word-break:keep-all;
	white-space:nowrap;
}

.leftNav-item ul li .text-item span i {
	font-size: 14px;
	line-height: 24px;
	position: absolute;
	left: 0;
	top: -2px;
	color: #444;
}

.leftNav-item ul li div {
	display: none;
}

.for-top {
	display: none;
}
@media (max-width: 880px) {
  .pcOrphone {
    display: none;
  }
  .phone {
    display: block;
  }
}
</style>
<style>
.el-tabs__item.is-disabled {
  color: #2e2e2e !important;
  cursor: default;
  font-weight: bold;
  font-size: 18px;
}
#tab-more {
  color: #3377aa;
}

</style>
