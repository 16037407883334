import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui';
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import 'element-ui/lib/theme-chalk/index.css';
import Qs from 'qs'



Vue.config.productionTip = false;
Vue.prototype.$ajax = axios;
Vue.prototype.Qs = Qs
// import mavonEditor from 'mavon-editor'
// import 'mavon-editor/dist/css/index.css'
Vue.use(ElementUI);
//https://ebook.t.educg.com/
//EduCGRunning  //服务运行状态检测点；
Vue.prototype.url= process.env.VUE_APP_URL
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
