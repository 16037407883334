<template>
   <div class="pro_recommend">
			<!-- <h2>保险产品推荐</h2> -->
			<ul>
				<li v-for="(item ,index) in moreAry" :key="index">
					<a href="javascript:;" class="goods-li-a">
						<img class="item-img" src="https://ebook.t.educg.com/fileDownload.out?fid=64b795fabd061e4ca4656672">
						<div class="item-text">
							<strong>{{ item.title }}</strong>
							<p>作者：{{ item.author?item.author:'暂无' }}</p>
							<!-- <span>查看详情</span> -->
                            <p>出版社：{{ item.press }}</p>
						</div>
						<!-- <i class="i-p-hot"></i> -->
					</a>
				</li>
			
			
			</ul>
		</div>
</template>
<script>
export default {
  data() {
    return {
      currentDate: new Date(),
      page: 1,
      currentPage3: 1,
      limit: 12,
      moreAryCur: [],
      moreCountCur: 0,
    };
  },
  props: {
    moreCount: Number,
    moreAry:Array
  },
  watch: {
    moreAry(val) {
      console.log(val);
      this.moreAryCur = val;
      console.log(this.moreAryCur);
    },
    moreCount(val) {
      console.log(val);
      this.moreCountCur = val;
      console.log(this.moreCountCur);
    },
  },
  mounted() {
    console.log(this.moreAry);
    this.moreAryCur = this.moreAry;
    this.moreCountCur = this.moreCount;
  },
  methods: {
    goTo(obj) {
      console.log(0);
      window.localStorage.setItem("anjing_detail_new", JSON.stringify(obj));
      // if(window.localStorage.getItem('anjing_name_ebook') && window.localStorage.getItem('anjing_name_ebook')!=''){//已登录
      // this.$router.replace({ path: "./course", query: { id: obj.seqid } });
      window.location.href=`/#/course?id=${obj.seqid}`
      // }
      // else {
      //   this.$confirm('登录后可继续阅读, 是否登录?', '登录提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     this.loginFun();
      //   }).catch(() => {

      //   });
      // }
      // this.$router.push({path:'./home_detail'})
    },
    loginFun() {
      debugger;
      let data = {
        op: "login",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            window.location.href = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.$emit("changePage", val);
    },
  },
};
</script>
<style scoped>


ul {
	list-style: none;
}

a {
	text-decoration: none;
	color: #555;
}

.pro_recommend {
	width: 1200px;
	height: auto;
	margin: 0 auto;
}

.pro_recommend h2 {
	font-size: 20px;
	color: #555;
	font-weight: bold;
	margin-bottom: 20px;
}

.pro_recommend ul {
	width: 100%;
	height: auto;
	overflow: hidden;
	background: #fff;
}

.pro_recommend ul li {
	float: left;
	position: relative;
	overflow: hidden;
	transition: all 0.3s ease 0s;
	width: 25%;
	height: 207px;
	border-right: 1px solid #ebebeb;
	border-bottom: 1px solid #ebebeb;
}

/* .pro_recommend ul li:first-child {
	border: none;
	width: 279px;
	height: 440px;
	border-right: 1px solid #ebebeb;
}

.pro_recommend ul li:nth-child(2) {
	float: right;
	border: none;
	width: 280px;
	height: 440px;
} */

.pro_recommend ul li a {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
}

.pro_recommend ul li img {
    position: absolute;
    width: 48%;
    height: 206px;
    top: 0;
    right: 0px;
	transition: all 0.3s ease 0s;
	vertical-align: middle;
}

/* .pro_recommend ul li:first-child img {
	width: 240px;
	height: 240px;
}

.pro_recommend ul li:nth-child(2) img {
	width: 240px;
	height: 240px;
} */

.pro_recommend ul li .item-text {
	position: absolute;
	top: 30px;
	left: 8px;
    text-align: left;;
}

.pro_recommend ul li .item-text strong {
	display: block;
	font-size: 18px;
	font-weight: normal;
	margin-bottom: 10px;
}

.pro_recommend ul li .item-text p {
	font-size: 14px;
	margin-bottom: 30px;
}

.pro_recommend ul li .item-text span {
	display: inline-block;
	height: 28px;
	line-height: 28px;
	border: 1px solid #fc6451;
	color: #fc6451;
	font-size: 14px;
	padding: 0 20px;
	border-radius: 2px;
	text-align: center;
	transition: all 0.3s ease 0s;
}

.pro_recommend ul li .i-p-hot {
	display: block;
	width: 60px;
	height: 60px;
	background: url(../images/images/icon_bg.png) no-repeat 0 -280px;
	position: absolute;
	right: 0px;
	top: 0px;
}

.pro_recommend ul li .i-p-new {
	display: block;
	width: 60px;
	height: 60px;
	background: url(../images/images/icon_bg.png) no-repeat -80px -280px;
	position: absolute;
	right: 0px;
	top: 0px;
}

.pro_recommend ul li .i-p-free {
	display: block;
	width: 60px;
	height: 60px;
	background: url(../images/images/icon_bg.png) no-repeat -160px -280px;
	position: absolute;
	right: 0px;
	top: 0px;
}

.pro_recommend ul li a:hover .item-img {
	right: -20px;
}
.item-img{
	border-width: 0px;
}
.pro_recommend ul li a:hover .item-text span {
	background: #fc6451;
	border: 1px solid #fc6451;
	/* color: #fff; */
}
</style>