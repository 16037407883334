<template>
  <div>
    <div class="box">
      <div
        v-for="(o, index) in newsBookAryCur"
        :key="index"
        @mouseenter="mouseenterFun(index)"
        @mouseleave="mouseleaveFun(index)"
        @click="goTo(o)"
        style="width:49%;margin-bottom:10px;min-height:374px;"
      >
        <el-card :body-style="{ padding: '0px' }" class="card" shadow="hover">
          <div style="height: 66%">
            <img :src="o.imgpath" class="image" />
          </div>
          <div style="padding: 6px" class="title-box">
            <span style="height: 40px;
    display: block;text-align: left;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;">{{ o.title }}</span>
            <p class=" bottom">
              <time class="time">作者：{{ o.author }}</time>
              <time class="seven seven-phone">" 本课程是面向计算机类专业的专业基础课，也是计算机系统能力培养的核心课程。通过理论部分引导学生逐步掌握计算机硬件系统各部件的组成和工作原理，掌握各部件的分析与设计方法，最终理解计算机硬件系统的整体结构和工作机理。在实验环节，引导学生设计并仿真实现一台单周期计算机硬件系统的模型机，使学生能够对计算机软、硬件的基本知识有更深刻的理解，逐步建立系统观，为本专业后续课程的学习打下基础。"
</time>
            </p>
          </div>
          <!-- <div
            style="position: absolute; top: 20px; left: 0; right: 0; bottom: 0"
            v-if="maskShow && index == maskIndex"
          >
            <p class="mask"></p>
            <p class="mask_detail">
              <time style="display:block">【作者】</time>
              <time class="">{{ o.author?o.author:'暂无' }}</time>
              <el-divider></el-divider>
              <time class="seven">{{ o.intro }}</time>
            </p>
          </div> -->
        </el-card>
      </div>
    </div>
    <div style="width: 100%; text-align: right">
      <el-pagination
        v-if="newsBookAryCur.length != 0"
        small
        layout="prev, pager, next"
        :total="totalCur"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="10"
      >
      </el-pagination>
    </div>
  </div>
</template>
<style>
.title-box {
  /* word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  /* height: 50%; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 设置行数 */
  -webkit-box-orient: vertical;
  height: 34%;
}
.seven{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7; /* 设置行数 */
  -webkit-box-orient: vertical;
}
.seven-phone{
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 16px;
    color: #898989;
    font-size: 12px;
}
.time {
  font-size: 13px;
  color: #999;
  /* word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  width: 100%;
  height:35px;
  display: block;
  margin-bottom: 5px;
  line-height: 17px;
  text-align: left;
  text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  overflow: hidden;
}

.bottom {
  margin-top: 7px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
  height: 100%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.el-card__body {
  height: 100%;
}

.clearfix:after {
  clear: both;
}
.box {
  display: flex;
  padding:0 10px;
  justify-content: space-between;
  /* justify-content:space-around; */
  flex-wrap: wrap;
 
  /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
  /* display: grid;
  grid-template-columns: 16% 16% 16% 16% 16%;
  grid-gap: 49px; */
}
.card {
  margin-bottom: 10px;
  cursor: pointer;
  height: 100%;
  position: relative;
}
.mask {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  bottom: 0;
  background: #000;
  opacity: 0.7;
  z-index: 1;
}
.mask_detail {

  width: 94%;
  color: #fff !important;
  font-weight: bold;
  position: absolute;
  z-index:20;
  top:20px;
  left: 5px;
  right:5px;

  display: block;
  margin-bottom: 5px;
  line-height: 17px;
  text-align: left;
  font-size:13px ;
  

}
.el-divider--horizontal{
  margin:10px 0 !important
}
</style>

<script>
export default {
  data() {
    return {
      maskShow: false,
      maskIndex: 0,
      currentDate: new Date(),
      page: 1,
      limit: 10,
      currentPage3: 1,
      newsBookAryCur: [],
      totalCur: 0,
    };
  },
  props: {
    total: Number,
    newsBookAry: Array,
  },
  watch: {
    newsBookAry(val) {
      console.log(val);
      this.newsBookAryCur = val;
      console.log(this.newsBookAryCur);
    },
    total(val) {
      console.log(val);
      this.totalCur = val;
      console.log(this.totalCur);
    },
  },
  //   watch:{
  //         type: {
  //       handler(val) {
  //         this.typeVal =val;
  //         console.log('00000')
  //         this.initColumn();
  //       },
  //     },
  //     },
  mounted() {
    // this.initColumn();
  },
  methods: {
    mouseenterFun(index) {
      this.maskShow = true;
      this.maskIndex = index;
    },
    mouseleaveFun(index) {
      this.maskShow = false;
      this.maskIndex = index;
    },
    goTo(obj) {
      console.log(0);
      window.localStorage.setItem("anjing_detail_new", JSON.stringify(obj));
      // if(window.localStorage.getItem('anjing_name_ebook') && window.localStorage.getItem('anjing_name_ebook')!=''){//已登录
      // this.$router.replace({ path: "./course", query: { id: obj.seqid } });
      window.location.href=`/#/course?id=${obj.seqid}`
      // }
      // else {

      //   this.$confirm('登录后可继续阅读, 是否登录?', '登录提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     this.loginFun();
      //   }).catch(() => {

      //   });
      // }
      // this.$router.push({ path: "./home_detail" });
    },
    loginFun() {
      debugger;
      let data = {
        op: "login",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            window.location.href = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      // this.emit('changeSize',val)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      //   this.initColumn();
      this.$emit("changePage", val);
    },
    // initColumn() {
    //   let data = {
    //     op: "list",
    //     page: this.page,
    //     column: "新书速递",
    //     type: this.type,
    //     limit: this.limit,
    //     istop: 1,
    //   };
    //   let _this = this;
    //   this.$ajax
    //     .post(this.url + "/index.do", _this.Qs.stringify(data))
    //     .then(function (response) {
    //       console.log(response);
    //       if (response.data.value == "ok") {
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
  },
};
</script>