<template>
  <div class="about"></div>
</template>
  <script>
export default {
  data() {return{}},
  mounted() {
    this.init();
  },

  methods: {
    init() {
      let data = {
        op: "login",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            window.location.href=response.data.data
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
  