var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"box-box",style:({
        width: _vm.addWindh,
        margin: '0px auto',
        display: 'flex',
        flexWrap:'wrap',
        color: '#fff',
    
      })},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"line",style:({
        width: _vm.addWindh,
        margin: ' 0 auto',
      })}),_c('div',{style:({
        width: _vm.addWindh,
        margin: '0px auto',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#fff',
      })}),_c('div',{staticClass:"line",staticStyle:{"margin-right":"40px"},style:({
        width: _vm.addWindh,
        margin: '0px auto'})},[_c('el-divider'),_c('a',{attrs:{"href":"https://course.educg.net","target":"_blank"}},[_vm._v("希冀在线实践教学平台")]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('a',{attrs:{"href":"https://www.educg.net/","target":"_blank"}},[_vm._v("希冀平台官网 ")]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('a',{attrs:{"href":"https://os.educg.net/","target":"_blank"}},[_vm._v("全国计算机系统能力大赛 ")]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('a',{attrs:{"href":"https://test.csia.org.cn/","target":"_blank"}},[_vm._v("全国青少年信息技术与计算思维能力评测（TCTY） ")]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('a',{attrs:{"href":"https://test.csia.org.cn/","target":"_blank"}},[_vm._v("全国青少年人工智能应用与创新能力评测（AITY） ")]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('a',{attrs:{"href":"https://sat.csia.org.cn/","target":"_blank"}},[_vm._v("信息技术人才-软件能力评测 ")]),_c('p',{staticStyle:{"margin-top":"10px"}},[_c('span',{staticStyle:{"color":"#ffbf64"}},[_vm._v("©北京航空航天大学 计算机学院 教学成果转化平台")]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('span',{staticStyle:{"color":"#ffbf64"}},[_vm._v("阔思格睿网络科技（北京）有限责任公司")]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('span',{staticStyle:{"color":"#ffbf64"}},[_vm._v("郑州云海科技有限公司")])],1),_c('p',[_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("豫ICP备18015569号-15")]),_vm._v(" "),(_vm.temp)?_c('el-divider',{attrs:{"direction":"vertical"}}):_vm._e(),(_vm.temp)?_c('span',{staticStyle:{"color":"#fff"}},[_vm._v("注：以上课程的所有权归"+_vm._s(_vm.qy)+"所有")]):_vm._e()],1),_c('p',{staticStyle:{"color":"#fff"}},[_vm._v("办公地址：北京市知春路锦秋国际大厦1404室")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v("教育数字基建")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/research.html","target":"_blank"}},[_vm._v("智能算力服务平台")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/OBE.html","target":"_blank"}},[_vm._v("工程教育认证")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/graduation.html","target":"_blank"}},[_vm._v("毕业设计")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/scangrade.html","target":"_blank"}},[_vm._v("纸质试卷在线评阅")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/exam.html","target":"_blank"}},[_vm._v("在线考试")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/mooc.html","target":"_blank"}},[_vm._v("慕课")])]),_c('li',[_c('a',{attrs:{"href":"https://plag.educg.net/","target":"_blank"}},[_vm._v("图文与代码查重")])]),_c('li',[_c('a',{attrs:{"href":"https://eduxiji.com/","target":"_blank"}},[_vm._v("理工类专业智慧教学平台")])]),_c('li',[_c('a',{attrs:{"href":"https://bd.educg.net/","target":"_blank"}},[_vm._v("教育大数据平台")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v("教学实验平台建设方案")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/robot.html","target":"_blank"}},[_vm._v("云端机器人实验平台")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/cloudDesk.html","target":"_blank"}},[_vm._v("新一代云桌面实验平台")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/fpga-cloud.html","target":"_blank"}},[_vm._v("FPGA实验云平台")])]),_c('li',[_c('a',{attrs:{"href":"https://3desk.educg.com/","target":"_blank"}},[_vm._v("3D云桌面")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/contest.html","target":"_blank"}},[_vm._v("比赛")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/oj.html","target":"_blank"}},[_vm._v("OnlineJudge")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/exp.html","target":"_blank"}},[_vm._v("在线虚拟实验开发环境")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v("课程建设方案")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/programming.html","target":"_blank"}},[_vm._v("程序设计")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/ds.html","target":"_blank"}},[_vm._v("算法与数据结构")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/os.html","target":"_blank"}},[_vm._v("操作系统")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/computer.html","target":"_blank"}},[_vm._v("计算机组成原理")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/compiler.html","target":"_blank"}},[_vm._v("编译技术实验")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/database.html","target":"_blank"}},[_vm._v("数据库实验")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/se.html","target":"_blank"}},[_vm._v("软件工程")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/parallel.html","target":"_blank"}},[_vm._v("并行计算")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/network.html","target":"_blank"}},[_vm._v("计算机网络")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/ai.html","target":"_blank"}},[_vm._v("人工智能类课程")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/bigdata.html","target":"_blank"}},[_vm._v("大数据类课程")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/blockchain.html","target":"_blank"}},[_vm._v("区块链类课程")])]),_c('li',[_c('a',{attrs:{"href":"https://www.educg.net/applied_courses.html","target":"_blank"}},[_vm._v("应用开发类课程")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v("联系我们")]),_c('ul',[_c('li',[_c('p',[_vm._v("产品合作")]),_vm._v(" "),_c('p',{staticStyle:{"font-size":"18px","color":"rgb(129, 245, 129)","font-weight":"bold"}},[_c('i',{staticClass:"el-icon-phone-outline"}),_vm._v("19910509699")])]),_c('li',[_c('p',[_vm._v("服务热线")]),_vm._v(" "),_c('p',{staticStyle:{"font-size":"18px","color":"rgb(129, 245, 129)","font-weight":"bold"}},[_c('i',{staticClass:"el-icon-phone-outline"}),_vm._v("4000697117")])]),_c('li',[_c('p',[_vm._v("在线咨询")]),_c('p',[_c('img',{staticStyle:{"width":"80px","height":"80px"},attrs:{"src":require("../images/WechatIMG3783.png")}})])])])])
}]

export { render, staticRenderFns }