<template>
    <div>
        <div class="container">
            <div
      style="
        overflow: hidden;
        height: 40px;
        display: flex;
        justify-content: space-between;
      "
    >
      <el-input
        placeholder="书名、作者"
        v-model="input3"
        class="input-with-select"
        style="width: 30%;margin-left:1% ;"

      >
        <el-button
          slot="append"
          icon="el-icon-search"
          size="mini"
          @click="searchFun"
          style=""
        ></el-button>
     
      </el-input>
      <el-pagination
      hide-on-single-page
      v-if="newsBookAryCur.length!=0"
        small
        layout="prev, pager, next"
        :total="20"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="10"
        style="text-align: right"
      >
      </el-pagination>
    </div>
		    <!-- Team members structure start -->
		    	<div class="team-members row">
	
		            <div class="single-member effect-5" v-for="(item,index) in newsBookAryCur" :key="index"    @click="goTo(item)">
		            	<div class="member-image">
		                	<!-- <img :src="item.imgpath" alt="Member"> -->
                            <img src="https://ebook.t.educg.com/fileDownload.out?fid=649ba2b39e7f592af9587242" alt="Member">
                            
		                </div>
		                <div class="member-info">
		                	<h4>{{ item.title }}</h4>
                            <div class="social-touch icon-colored">
		                    	<span style="font-size: 12px;color:rgb(147 145 145);">   <i class="slide-body-nub-icon"></i>{{ item.joinnumber }}</span>
		                    </div>
		                    <h6 style="color:rgb(124 121 121);font-size: 13px;width:95%">作者：{{ item.author?item.author:'暂无' }}</h6>
                            
		                    <p class="seven">{{ item.intro }}</p>
		                   
		                </div>
		            </div>

		            
		        </div>
		    <!-- Team members structure start -->
		    </div>
 
    </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      currentPage3: 1,
      newsBookAryCur:[],
      totalCur:'',
      input3:''
    };
  },
  props: {
    total: Number,
    newsBookAry: Array,
  },
  watch: {
    newsBookAry(val) {
      console.log(val);
      this.newsBookAryCur = val;
      console.log(this.newsBookAryCur);
    },
    total(val) {
      console.log(val);
      this.totalCur = val;
      console.log(this.totalCur);
    },
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      // this.emit('changeSize',val)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      //   this.page = val;
      //   //   this.initColumn();
      //   this.$emit("changePage", val);
    },
    goTo(obj) {
      console.log(0);
      window.localStorage.setItem("anjing_detail_new", JSON.stringify(obj));
      window.location.href=`/#/course?id=${obj.seqid}`
  
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      // this.emit('changeSize',val)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      //   this.initColumn();
      this.$emit("changePage", val);
    },
    searchFun() {
  
  window.localStorage.setItem("anjing_input3_name", this.input3);
  if (window.location.href.indexOf("search_list") != -1) {
    this.$emit("searchFun", "0");
  } else {
    this.$router.push({ path: "./search_list" });
  }
},
  },
};
</script>
<style scoped>
@import url(../assets/style001.css);
@import url(../assets/fontStyle.css);
.seven{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-size:12px;
  width:95%;
  color:#000;
  -webkit-line-clamp: 5; /* 设置行数 */
  -webkit-box-orient: vertical;
}
</style>